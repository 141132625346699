html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}
body {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: sans-serif;
  color: white;
  background: var(--bg, rgb(40, 40, 40));
  font-size: 16px;
}
h1 {
  font-size: 2rem;
  padding: 2rem;
}
.img-wrapper {
  max-width: 600px;
  padding: 0 1rem;
  margin: 2rem;
  cursor: pointer;
}
.img-wrapper img {
  width: 100%;
}
button {
  font-size: inherit;
  font-size: 1rem;
  padding: .5rem;
  margin: 8rem 0;
}
