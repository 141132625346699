html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  color: #fff;
  background: var(--bg, #282828);
  flex-direction: column;
  align-items: center;
  font-family: sans-serif;
  font-size: 16px;
  display: flex;
}

h1 {
  padding: 2rem;
  font-size: 2rem;
}

.img-wrapper {
  max-width: 600px;
  cursor: pointer;
  margin: 2rem;
  padding: 0 1rem;
}

.img-wrapper img {
  width: 100%;
}

button {
  font-size: inherit;
  margin: 8rem 0;
  padding: .5rem;
  font-size: 1rem;
}

/*# sourceMappingURL=index.08f6f9e7.css.map */
